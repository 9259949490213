.nav-wrapper {
   background-color: white;
}

.nav-container {
   margin: 0 auto;
   padding: 10px 30px 5px;
   display: flex;
   align-items: center;
   /*vertically center*/
   max-width: 1160px;
}

.nav-container a {
   color: #467d8a;
   font-size: 0.9rem;
}

.nav-container nav {
   margin-left: auto;
}

.nav-container ul {
   list-style-type: none;
}

.nav-container li {
   display: inline-block;
   margin-left: 1.4rem;
}

.nav-logo {
   display: inline-block;
   width: 80px;
   height: 80px;
   position: relative;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   background-color: #D7EEF6;
   border-radius: 50%;
}

.nav-logo img {
   width: 70px;
   object-fit: contain;
}

.logo-text {
   font-family: 'Rampart One', cursive;
   color: #333;
   font-size: 1.8rem;
   letter-spacing: -2px;
}

.logo-text span {
   font-size: 1.5rem;
}

.nav-txt {
   margin-left: 15px;
   font-size: 1.5rem;
}

.nav-container .hamburger {
   display: none;
}

@media screen and (max-width: 767px) {
   .nav-wrapper {
      position: sticky;
      top: 0;
      box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.1);
      z-index: 99;
   }


   .nav-logo {
      width: 60px;
      height: 60px;
   }

   .nav-logo img {
      width: 50px;
   }

   .logo-text {
      font-size: 1.5rem;
   }

   .logo-text span {
      font-size: 1.2rem;
   }

   .nav-txt {
      margin-left: 10px;
      font-size: 1.2rem;
   }
}