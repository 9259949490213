input,
label,
textarea {
   display: block;
}

textarea {
   height: 50px;
   width: 250px;
}

button {
   padding: 10px;
   margin: 10px;
   background-color: cadetblue;
   color: white;
}

.admin-wrapper {
   margin: 0 auto;
   max-width: 1160px;
   padding: 0 30px;
}

.addCorpForm {
   margin: 30px 0;
}