.cp-wrapper {
   max-width: 1160px;
   margin: 0 auto;
   position: relative;
}

.cp-wrapper::after {
   content: "";
   position: absolute;
   display: inline-block;
   left: 100%;
   transform: translateX(-120%);
   top: 380px;
   width: 400px;
   height: 400px;
   background-color: #ffe9d5;
   border-radius: 50%;
   z-index: -3;
}

.addreviews {
   width: 500px;
   margin: 30px auto 0;
   background-color: #D7EEF6;
   /* border-radius: 10px; */
   padding-bottom: 15px;
   box-shadow: 0 0 10px #aaa;
}

.addreviews .form-title {
   width: 90%;
   margin: 0 auto;
   text-align: center;
   padding: 15px 0;
   border-bottom: 1px solid #aaa;
}


.addreviews form {
   /* position: relative; */
   width: 80%;
   margin: 0 auto;
}

.addreviews input,
.addreviews textarea,
.addreviews select {
   display: inline-block;
   border: none;
   box-shadow: inset 0 0 2px #999;
}

.addreviews textarea {
   margin-top: 10px;
   height: 120px;
   max-width: 100%;
   min-width: 100%;
   resize: none;
}

.addreviews button {
   display: block;
   width: 120px;
   margin: 0 auto;
   padding: 15px 15px;
   background-color: #467d8a;
   color: white;
   font-size: 1rem;
   /* border: 2px solid #ffe9d5; */
}

.addreviews label {
   font-size: 0.85rem;
   text-align: center;
   display: inline-block;
   width: 60px;
   border-radius: 5px;
   margin-right: 60px;
   border: 1px solid #777;
   position: relative;
}

label.label-long {
   margin-top: 10px;
   width: 100px;
}

.input-field {
   margin: 15px 0;
}

.must-notion {
   position: absolute;
   top: 7px;
   right: -32px;
   width: 35px;
   display: inline-block;
   font-size: 0.65rem;
   color: #467d8a;
}

.rate-stars {
   display: inline-block;
   vertical-align: middle;
}

.rate-star {
   display: inline-block;
   cursor: pointer;
}

.rate-star img {
   width: 28px;
   height: 28px;
}

.reviews-header {
   margin-bottom: 10px;
   display: flex;
   flex-direction: column;
   align-items: center;
}

.reviews-title2 {
   font-weight: 700;
   color: #777;
   font-size: 0.9rem;
}

.reviews-title2 span {
   display: inline-block;
   color: #467d8a;
}

.reviews-avepoint {
   padding: 15px 0;
   display: flex;
   align-items: center;
}

.reviews-avepoint span {
   font-weight: 700;
   color: #777;
}

.avepoint {
   margin-left: 5px;
   display: flex;
   align-items: center;
   font-family: 'Ubuntu', sans-serif;
   font-size: 1.9rem;
   font-weight: 700;
}

.avepoint img {
   width: 28px;
}

.scrll-lap {
   max-height: 980px;
   overflow-y: scroll;
}

@media screen and (max-width: 767px) {


   .addreviews {
      width: 100%;
   }

   .scrll-lap {
      max-height: none;
      overflow-y: auto;
   }
}