* {
  box-sizing: border-box;
}

@import url('https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@300;400;700&family=Rampart+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+JP&display=swap');

body {
  margin: 0;
  font-family: 'M PLUS Rounded 1c', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
  color: #666;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  font-size: 1.8rem;
  font-weight: 700;
}

h2 {
  font-size: 1.2rem;
  font-weight: 700;
  margin: 0;
}

a {
  font-family: 'Ubuntu', sans-serif;
  font-weight: bold;
  color: #666;
  text-decoration: none;
  transition: ease 0.4s;
}

button {
  border: none;
  border-radius: 10px;
  color: #666;
  transition: ease 0.4s;
  font-weight: 700;
  cursor: pointer;
}

a:hover,
button:hover {
  opacity: 0.8;
}

input,
textarea,
select {
  border: 1px solid #467d8a;
  border-radius: 5px;
  padding: 5px 10px;
  height: 40px;
  font-size: 1.1rem;
}

input:focus {
  outline: none;
}

select:focus {
  outline: none;
}

textarea:focus {
  outline: none;
}