.mainsec {
   position: relative;
   margin: 30px auto;
   max-width: 1160px;
   padding: 0 30px;
}

.mainsec::before {
   content: "";
   position: absolute;
   display: inline-block;
   left: 10%;
   top: 140px;
   width: 350px;
   height: 350px;
   background-color: #ffe9d5;
   border-radius: 50%;
   z-index: -3;
}

.mainsec::after {
   content: "";
   position: absolute;
   left: 100%;
   transform: translateX(-100%);
   top: -620px;
   width: 750px;
   height: 750px;
   background-color: #D7EEF6;
   border-radius: 50%;
   z-index: -3;
}

.main-txt {
   text-align: center;
}

.srchbox {
   margin: 30px auto;
   width: 60%;
   height: auto;
   padding: 30px 15px;
   max-width: 400px;
   text-align: center;
   background: linear-gradient(0.3turn, #d3f6ff2c, rgba(255, 255, 255, 0.75), #d3f6ff2c, #d3f6ff2c, #d3f6ff2c, #d3f6ff2c, #d3f6ff2c);
   backdrop-filter: blur(5px);
   border: 2px solid #e4faff92;
   border-radius: 5px;
}

.srchbox input {
   width: 90%;
   margin: 0 auto;
   transition: all .4s ease;
   font-weight: 300;
}

.srchbox input:focus {
   border: 1.5px solid #ffe9d5;
}

.schlabel {
   padding-bottom: 30px;
   color: #467d8a;
}

.sch-results {
   position: absolute;
   width: 340px;
   left: 50%;
   top: 320px;
   transform: translateX(-50%);
   text-align: left;
   background-color: white;
}

.sch-result {
   width: auto;
   padding: 10px 10px;
}

.sch-result a {
   font-family: 'M PLUS Rounded 1c', sans-serif;
}

.reviews-wrapper {
   background-color: #D7EEF6;
   margin-top: 60px;
   background-color: #d3f6ff2c;
   backdrop-filter: blur(5px);
   border-top: 2px solid #e4faff92;
}

.reviews {
   margin: 0 auto;
   padding: 15px 30px;
   max-width: 1160px;
   z-index: 1;
}

.reviews-title {
   text-align: center;
   padding-bottom: 15px;
   color: #777;
}

@media screen and (max-width: 767px) {
   .srchbox {
      min-width: 100%;
   }

   .sch-results {
      top: 300px;
      left: 15%;
      transform: translateX(0%);
      border: 1px solid #777;
      width: 350px;
   }

   .reviews {
      padding: 15px 5px;
   }
}