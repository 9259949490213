.about-wrapper {
   max-width: 1160px;
   margin: 60px auto;
   padding: 0 30px;
   font-family: 'Noto Serif JP', serif;
   text-align: center;
   font-size: 1.2rem;
}

@media screen and (max-width: 767px) {
   .about-wrapper {
      padding: 0 15px;
      text-align: left;
   }
}