.rev-container {
   margin: 0 auto;
   padding: 15px 0;
   width: 90%;
   border-top: 2px solid #b0cfd727;
}

.rev-container a {
   padding-left: 10px;
   font-family: 'M PLUS Rounded 1c', sans-serif;
   font-size: 1.15rem;
   color: #467d8a;
}

.review {
   display: flex;
   font-size: 0.9rem;
}

.review .item {
   margin: 15px 0;
}

.rev-point {
   display: flex;
   align-items: center;
}

.rev-point img {
   display: inline-block;
   margin-left: -3px;
}

.rev-basic {
   min-width: 35%;
   font-size: 1.0rem;
}

.review span {
   display: inline-block;
   text-align: center;
   width: 70px;
   margin-right: 10px;
   border: 1px solid #666;
   border-radius: 5px;
   font-size: 0.9rem;
}

.rev-basic-other {
   padding-left: 30px;
}

.rev-detail {
   flex-grow: 1;
   /*grow to maximum of remained space*/
   padding-left: 60px;
   position: relative;
}

.rev-detail span {
   display: block;
   margin-bottom: 15px;
}

.rev-content {
   white-space: pre-wrap;
   /*enable return*/
}

.rev-createdOn {
   text-align: right;
   font-size: 0.8rem;
   margin-top: 30px;
}

@media screen and (max-width: 767px) {
   .review {
      flex-direction: column;
   }

   .rev-basic-other {
      padding-left: 10px;
   }

   .rev-detail {
      padding-left: 10px;
   }
}